.section{
    background: linear-gradient(var(--img-back)),url('../assets/space.jpg');
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    position: sticky;
    top:0;
}
.section__box{
    height: 80vh;
    width: 90vw;
    display: flex;
    align-items:center;
    justify-content: space-between;
    background: linear-gradient(var(--glass-white));
    backdrop-filter: blur(1rem);
    border-radius: 3rem;
    margin: 1rem auto;
    position: relative;
}
.section__box::before, .section__box::after{
    position: absolute;
    content: '';

}
.section__box::before{
    position: absolute;
    width: 100%;
    height: 100px;
    top:10%;
    background-color:var(--glass-before);
    z-index: 1;
}
.section__content p{
 color: var(--p-status);
 text-transform: uppercase;
 font-size: 10px;
 margin-top: 1rem;
}
.section__content .small__p{
    color: var(--main-white);
    margin-top: 1rem;
}
.section__content button{
    background: transparent;
    padding: 15px 30px;
    margin-right: 1rem;
    margin-block: 1.3rem;
    text-transform: uppercase;
    color: var(--main-white);
    border: 1px solid var(--main-white);
}
.section__content button:hover{
    transform: scale(115%);
    transition: 350ms;
}
@media (min-width:1000px){
    .section__image{
        width: 650px;
        cursor: pointer;

    }
    .section__img:hover{
        transform: scale(110%);
        transition:450ms;
    }
    .section__content{
        margin-left: 1rem;
        width: 550px;
    }
    .content__header{
        font-size: 65px;
        letter-spacing: 3px;
        color: var(--main-white);
        text-transform: uppercase;
        line-height: 75px;
        text-decoration: underline;
    }
}

@media (max-width:1000px)
{
    .section__box{
        flex-direction:column-reverse;
        justify-content: center;
    }
    .section__image{
        width: 260px;
    }
    .section__content{
        text-align: center;
        padding: 30px;
    }
    .content__header{
        margin-left: 1rem;
        font-size:30px;
        color: var(--main-white);
        text-transform: uppercase;
    }
}

@media(max-width:550px){
    .section__content button{
        padding: 10px 20px;
        margin-right: .5rem;
    }
    .section__image{
        width: 220px;
    }
    .section__content .small__p{
        font-size: 8px;
    }
    .section__contentp{
        font-size: 7px;
        margin-top: 1rem;
    }
    .content__header{
        font-size: 21px;
    }
}

