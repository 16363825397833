.works{
    width: 100%;
    min-height: 10vh;
    background: linear-gradient(45deg,var(--glass-secondary));
    position: relative;
    overflow: hidden;
}
.cards{
    min-height: 200px;
    padding: 20px;
    background-color: var(--secondary-cards);
    cursor: pointer;
}
.cards h3{
    color: var(--main-white);
    text-decoration: underline;
}
.cards p{
    margin-block: 1rem;
    color: var(--glass-gray);
}
.long{
    grid-column: span 2;
    grid-row: span 2;
}
.cards:hover p{
    color: var(--main-white);
    transition: 250ms;
}
@media(min-width:900px){
    .works__container{
        display: grid;
        padding: 20px;
        margin-block: 1rem;
        grid-template-columns: repeat(auto-fill,minmax(35rem,1rem));
        gap: 1rem;
    }
}

@media(max-width:900px){
    .works__container{
        padding: 20px;
        gap:2rem;
    }
    .cards{
        margin-block: 1rem;
    }
}