.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}
.header__logo img{
    width: 100px;
}
.list__items li a{
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    color: var(--main-white);
}
@media (min-width:50em) {
    .list__items{
        display:flex;
        padding-inline: 2rem;
        padding-block: 1.5rem;
        gap: 3rem;
        list-style: none;
        background: var(--blur);
        backdrop-filter: blur(.3rem);
        margin: 0;
    }

    .menu__icon, .close__icon{
        display: none;
    }
}
@media (max-width:50em){
    .list__items{
        display: flex;
        flex-direction: column;
        position: fixed;
        inset:0 0 0 40%;
        align-items: center;
        justify-content: center;
        gap: 4rem;
        list-style: none;
        background-color: var(--blur-small);
        z-index: 999;
        transform: translateX(100%);
        transition: 450ms;
    }

    .list__items.active{
        transform: translateX(0);
        transition: 450ms;
    }
    .menu__icon{
        display: block;
        position: absolute;
        right: 2rem;
        top: 1.5rem;
        color: var(--main-white);
        cursor: pointer;
    }
    .menu__icon .menu{
        font-size: 40px;
    }
    .close__icon{
        color: var(--main-white);
        cursor: pointer;
    }
}