.benefits{
    width: 100%;
    min-height: 100vh;
    background: red;
    position: relative;
    background: linear-gradient(45deg, var(--glass-secondary));
    overflow: hidden;
}
.benefits__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 1rem;
}
.benefits__content h1{
    color: var(--main-white);
    font-size: 60px;
    text-decoration: underline;
}
.benefits__content p{
    color: var(--main-white);
    margin-top: 1rem;
}
.card{
    padding: 15px;
    position: relative;
    background: var(--glass-gray);
    cursor: pointer;
}
.card img{
    opacity: .6;
}
.card button{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: none;
    color: VAR(--main-white);
    border: none;
    padding: 10px 30px;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 0;
    font-size: 30px;
}
.card:hover button{
    opacity: 1;
    transition: 550ms;
}
.card:hover img{
    opacity: 1;
}
@media (min-width:900px){
    .benefits__container{
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(25rem,1fr));
        padding: 20px;
        gap: 2rem;
    }

}
@media(max-width:500px){
    .benefits__container{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem,1fr));
        padding: 10px;
        gap: 2rem;
    }
    .benefits__content p{
        color: var(--main-white);
        margin-top: 1rem;
        font-size: 10px;
    }
}