.contact{
    width: 100%;
    height: 100vh;
    background: var(--pink);
    overflow: hidden;
}
.input__container{
    width: 70%;
    height: 90%;
    background-color: var(--glass-gray);
    backdrop-filter: blur(5rem);
    margin: 2rem auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.input__container input{
    width: 80%;
    padding: 25px;
    border: none;
    outline: none;
}
.input__container button{
    padding: 10px 40px;
    background-color: transparent;
    border: 1px solid var(--main-white);
    cursor: pointer;
    color: var(--main-white);
}
.a-icon .icon{
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 35px;
    color: var(--main-white);
    z-index: 999;
}

@media (max-width:900px){
    .input__container{
        width: 90%;
    }
    .input__container input{
        width: 90%;
        padding: 20px;
    }
}